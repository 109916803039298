<template>
  <MainContent>
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 col-lg-10">
          <div class="header">
            <div class="header-body">
              <div class="col">
                <h1 class="header-title mt-4">Account</h1>
              </div>
              <div class="col-12">
                <ul class="nav nav-tabs nav-overflow header-tabs">
                  <li class="nav-item" v-for="(navItem, idx) in navItems" :key="idx">
                    <router-link
                      :to="{ name: navItem.route }"
                      class="nav-link"
                      :class="{ active: navItem.route === activeSettingsTab }"
                      >{{ navItem.title }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <SlideFadeTransition>
            <router-view />
          </SlideFadeTransition>
        </div>
      </div>
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import SlideFadeTransition from '@/components/Transitions/SlideFadeTransition'
import { trackEvent } from '@/lib/analytics'

export default {
  name: 'AccountPage',
  components: {
    MainContent,
    SlideFadeTransition,
  },
  data: () => ({
    activeSettingsTab: 'account-profile',
    navItems: [
      {
        title: 'Profile',
        route: 'account-profile',
      },
      {
        title: 'Notifications',
        route: 'account-notifications',
      },
    ],
  }),
  watch: {
    '$route.name': {
      immediate: true,
      handler(name) {
        this.activeSettingsTab = name
      },
    },
  },
  mounted() {
    trackEvent(this.$intercom, 'Account settings')
  },
}
</script>
